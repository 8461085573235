<template>
  <div class="page-face">
    <!-- <div class='face-header'>
           <div class='header-img'></div>
       </div> -->
    <div class="face-main">
      <div class="main-h">确认{{ realName }}本人操作</div>
      <div class="main-text">请保持正脸在取景框中根据屏幕指示完成识别</div>
      <div class="main-img"></div>
    </div>
    <div class="footer-btn" @click="goFace">开始人脸识别</div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs, reactive } from "vue";
import APP from "@/utils/App";
import API from "../../server/api";
import { routerTag, dealImage } from "../../utils/index";
import { Toast } from "vant";
import axios from "axios";
import { useStore } from "vuex";
export default {
  setup() {
    const $store = useStore();
    const state = reactive({
      faceToken: "",
      faceReqId: "",
      faceUrl: "",
      realName: "",
      imageBest: "", // 最佳照片
      userId: "",
      creditNo: "",
      liveToken: "",
      token: "",
    });
    // 活体识别
    const goFace = async() => {
    await getLiveToken();
      APP.livingDetection({ bizToken: state.liveToken },  (res) => {
        console.log(res,'resres');
        if (JSON.stringify(res.response) === "{}") return;
        if (res.response.resultCode === 0) {
          console.log(res.response, "返回的结果");
          state.token = res.response.detectToken;
           
              getOss("data:image/jpg;base64," + res.response.detectData);

          
        } else {
          return false;
        }
      });
    };

    // 上传到oss
    const getOss = async (file) => {
      // console.log(file,'file');
      const fileKey = "LRD-FILEURL";
      const res = await API.uploadOss({
        fileName: "file",
        fileType: fileKey,
      });
     
      const { host, accessId, dir, policy, signature } = res.result;
      
      const data = new FormData();
      data.append("key", dir);
      data.append("OSSAccessKeyId", accessId);
      data.append("policy", policy);
      data.append("Signature", signature);
      data.append("success_action_status", "200");
      data.append("file", base64ToFile(file));
    
      const baseUrl = host;
      axios({
        url: baseUrl,
        method: "post",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.status === 200) {
            const fileUrl = baseUrl + dir;
            interFace(fileUrl);
          }
        })
        .catch((e) => {
          console.log("失败");
        });
    };
    //获取用户姓名
    const getName = async () => {
      const userName = await API.getRealName();
      state.realName = userName.realName;
    };
    //获取人脸token
    const getLiveToken = async () => {
      const getToken = await API.getliveToken();
      console.log(getToken,'getToken')
      if (getToken.code == 200) {
        const { bizToken }=getToken.result
        state.liveToken =bizToken;
      } else {
        Toast("请稍后再试！");
      }
    };
    const getNewFormDate = () => {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var srtDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (srtDate >= 1 && srtDate <= 9) {
        srtDate = "0" + srtDate;
      }
      var currentdate = year + month + srtDate;
      return currentdate;
    };

    const base64ToFile = (dataUrl, name) => {
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], name + ".jpg", { type: "image/jpg" });
    };
    const interFace = async (fileUrl) => {
      // 上传到oss后拿到的路径
      const faceResult = await API.liveBody({
        fileUrl: fileUrl, // 最佳人脸照片URL
        token: state.liveToken, // 全景照片URL
        userId: state.userId, // userId
        creditNo: state.creditNo, // 授信单号
      });
      // console.log(faceResult, 98);
      if (faceResult.code == '200') {
        $store.state.faceSuccess = true;
        Toast.success(faceResult.result);
        setTimeout(function () {
          APP.BACK(-1);
        }, 800);
      } else {
        Toast.fail(faceResult.result);
      }
    };

    onMounted(() => {
      getName();
      state.userId = localStorage.getItem("userId");
      state.creditNo = localStorage.getItem("creditNo");
      APP.SET_TITLE("活体检测");
      // state.realName = localStorage.getItem('authName')
    });
    return {
      ...toRefs(state),
      goFace,
      getOss,
    };
  },
};
</script>
